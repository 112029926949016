@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/OpenSans/OpenSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/OpenSansCondensed/OpenSansCondensed-Bold.woff") format("woff");
}

@font-face {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/Verdana/Verdana-Bold.woff") format("woff");
}

@font-face {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/Verdana/Verdana.woff") format("woff");
}